<template>
  <div class="wallet">
    <panel-title icon="el-icon-wallet" title="资金明细" />

    <panel-wrap>
      <div class="account-info">
        <div class="avatar">
          <img src="http://dummyimage.com/60x60/39A2DB" alt="" />
        </div>
        <div class="info">
          <el-row :gutter="40">
            <el-col :span="12">
              <div class="title">深圳XXXXXXXXXX有限公司</div>
              <div class="desc">
                本公司主营软件开发、APP开发、小程序开发、高端高难度的应用开发、各种软件系统开发，顶尖技术团队，减少后顾之忧，保证您满意，欢迎咨询洽谈
              </div>
            </el-col>
            <el-col :span="12">
              <div class="bal-title">可用余额</div>
              <div class="balance">￥0.00</div>
            </el-col>
          </el-row>
        </div>
      </div>
    </panel-wrap>

    <panel-wrap class="nav-panel">
      <el-row :gutter="10">
        <el-col :span="6">
          <div class="box">
            <div class="icon-wrap">
              <i class="el-icon-bank-card"></i>
            </div>
            <div class="title-wrap">
              <div class="title">银行卡管理</div>
              <div class="desc">点击可快捷进入管理银行卡</div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="box">
            <div class="icon-wrap">
              <i class="el-icon-s-finance"></i>
            </div>
            <div class="title-wrap">
              <div class="title">在线充值</div>
              <div class="desc">点击可快速充值金额进行产品购买</div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="box">
            <div class="icon-wrap">
              <i class="el-icon-s-shop"></i>
            </div>
            <div class="title-wrap">
              <div class="title">线下充值</div>
              <div class="desc">可线下充值好后提交信息至平台进行充值</div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="box">
            <div class="icon-wrap">
              <i class="el-icon-coin"></i>
            </div>
            <div class="title-wrap">
              <div class="title">账户提现</div>
              <div class="desc">点击可对平台可用资金进行提现操作</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </panel-wrap>

    <panel-content>
      <div slot="filter">
        <div class="top-filter">
          <div class="left">
            <span class="active">全部需求</span>
            <span>等待审核需求</span>
            <span>已确认需求</span>
            <span>已拒绝需求</span>
          </div>
          <div class="right">
            <span
              class="more-filter-btn"
              @click="showMoreFilter = !showMoreFilter"
              >高级筛选

              <i
                :class="[
                  showMoreFilter ? 'el-icon-caret-top' : 'el-icon-caret-bottom',
                ]"
              ></i>
            </span>
          </div>
        </div>
        <div class="bottom-filter" v-show="showMoreFilter">
          <span>交易用途：</span>

          <el-select
            v-model="value"
            placeholder="请选择交易用途"
            size="medium"
            style="margin: 0 10px; width: 130px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>

          <span>时间：</span>
          <el-date-picker
            style="margin: 0 10px"
            size="medium"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>

          <el-button type="primary" size="medium">立即查询</el-button>
        </div>
      </div>
      <div class="table-wrap" slot="content">
        <el-table
          size="mini"
          ref="multipleTable"
          :data="[]"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          header-row-class-name="table-head-row"
        >
          <el-table-column label="流水号"> </el-table-column>

          <el-table-column prop="name" label="支付方式" width="120">
          </el-table-column>

          <el-table-column prop="name" label="操作金额" width="120">
          </el-table-column>

          <el-table-column prop="name" label="操作后金额" width="120">
          </el-table-column>

          <el-table-column prop="name" label="交易用途" width="120">
          </el-table-column>

          <el-table-column prop="name" label="时间" width="120">
          </el-table-column>

          <el-table-column prop="name" label="状态" width="120">
          </el-table-column>
        </el-table>
      </div>
    </panel-content>
  </div>
</template>

<script>
import PanelContent from "./PanelContent.vue";
import PanelTitle from "./PanelTitle.vue";
import PanelWrap from "./PanelWrap.vue";
export default {
  components: { PanelTitle, PanelContent, PanelWrap },

  data() {
    return {
      showMoreFilter: false,

      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.wallet {
  .account-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .avatar {
      cursor: pointer;
      flex: 0 0 auto;

      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      img {
        transition: all 0.8s ease-in;
      }

      &:hover,
      &:active {
        img {
          transform: scale(1.2);
        }
      }
    }

    .info {
      flex: 1 1 auto;
      margin-left: 15px;

      .title {
        cursor: pointer;
        color: #666666;
        font-size: 16px;
        margin-top: 5px;
        margin-bottom: 8px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover,
        &:active {
          color: red;
        }
      }

      .desc {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        font-size: 12px;
        line-height: 22px;
        height: 22px;
        color: #999;
      }

      .bal-title {
        color: #666;
      }

      .balance {
        color: #e62d31;
        font-size: 24px;
        font-weight: bold;
      }
    }
  }

  .nav-panel {
    .box {
      cursor: pointer;
      user-select: none;
      height: 100px;
      display: flex;
      align-items: center;

      border: 1px solid #ededed;

      &:active,
      &:hover {
        box-shadow: 0px 0px 10px #ddd;

        .icon-wrap i,
        .title-wrap .title {
          color: #e62d31;
        }
      }

      .icon-wrap {
        text-align: center;
        width: 80px;
        flex: 0 0 auto;
        i {
          color: #999;
          font-size: 40px;
        }
      }

      .title-wrap {
        flex: 1 1 auto;

        .title {
          color: #666666;
          font-size: 14px;

          margin-bottom: 3px;
        }

        .desc {
          color: #999999;
          line-height: 18px;
          font-size: 12px;
        }
      }
    }
  }

  .top-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      span {
        cursor: pointer;
        display: inline-block;
        padding-right: 20px;
        margin-right: 20px;
        border-right: 1px solid #ccc;

        font-size: 14px;

        line-height: 17px;

        &:hover,
        &:active,
        &.active {
          color: red;
        }
      }

      span:last-child {
        border-right: none;
      }
    }

    .right {
      .more-filter-btn {
        user-select: none;
        cursor: pointer;
        font-size: 14px;
        color: red;
        margin-left: 10px;
      }
    }
  }

  .bottom-filter {
    margin-top: 15px;
  }

  .table-wrap {
    margin-top: 18px;
    ::v-deep .table-head-row th {
      background: #f4f5f9;
      color: #666;
    }
  }
}
</style>