<template>
  <div class="panel-wrap">
    <slot />
  </div>
</template>

<script>
export default {
  name: "panel-wrap",
};
</script>

<style lang="scss" scoped>
.panel-wrap {
  padding: 25px;
  background: #fff;
  margin: 15px 0;
}
</style>